import React, { useState } from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import HandDrawing from "../../../../../components/hand-drawing"
import Heading from "../../../../../components/heading"
import Input from "../../../../../components/input"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import TextLink from "../../../../../components/textlink"

const Page = () => {
  const { getAnswer, submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })
  const [showTipp, setShowTipp] = useState(0)

  const taskProps = {
    courseId: "hoelderlins-reisen",
    chapterId: "02-reiseerinnerung",
    taskId: "handschrift-entziffern-stadt",
  }

  const answer = getAnswer({
    ...taskProps,
    taskId: "handschrift-entziffern-schrift",
  })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
        })
        navigate(
          "/kurse/hoelderlins-reisen/02-reiseerinnerung/handschrift-entziffern/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Eine Reisestation wird zum Gedicht" />
      <Stack>
        <Heading as="h2" level={2}>
          Eine Reisestation wird zum Gedicht
        </Heading>
        <HandDrawing ratio={0.4} saveData={answer} disabled />
        <Paragraph>
          Wow! Ein tolles Kunstwerk. Aber in welcher Stadt war Hölderlin jetzt
          eigentlich?
        </Paragraph>
        <Stack space={3}>
          <Input id="stadt" name="stadt" label="Es geht um" />
          {showTipp > 0 && <Text>Das gesuchter Wort beginnt mit "H".</Text>}
          {showTipp > 1 && (
            <Text>
              Der Name der Stadt erinnert an ein Obst, das die Lippen blau
              färbt.
            </Text>
          )}
          {showTipp > 2 && (
            <Text>
              Auf dem zweiten Teil des Wortes steht häufig eine Burg oder ein
              Schloss.{" "}
              <span role="img" aria-label="Schloss">
                🏰
              </span>
            </Text>
          )}
          {showTipp <= 2 && (
            <TextLink
              onClick={(event) => {
                event.preventDefault()
                setShowTipp(showTipp + 1)
              }}
            >
              Ich will {showTipp > 0 && "noch "}einen Tipp
            </TextLink>
          )}
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
